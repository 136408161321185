import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'

type ButtonProp = {
    title: string
    routePath: string
    color?: string
    width?: string
}

export const ThemedRouteButton = ({
    title,
    routePath,
    color,
    width,
}: ButtonProp) => {
    const navigate = useNavigate()

    const handleRoute = (route: string) => {
        navigate(`${route}`)
    }

    return (
        <Button
            variant="contained"
            sx={{
                backgroundColor: color || '#DDE4E6',
                color: 'black',
                width: width || '100%',
                /* fontWeight: 'bold', */
            }}
            onClick={() => handleRoute(routePath)}
        >
            {title}
        </Button>
    )
}
