import { Grid } from '@mui/material'
import { GenericCardHeader } from '../../../Components/GenericCardComponents'
import co_so_bao from '../../../Resources/Orphanages/co_so_bao_tro_xa_hoi_thien_duyen.png'
import orphans_in_bed from '../../../Resources/Orphans/metal_beds_of_orphans_2.png'

const OrphanageInfo = () => {
    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ pt: 6 }}
        >
            <Grid item xs={5} lg={6} container sx={{ pl: 4, pr: 4 }}>
                <Grid item xs={12}>
                    <GenericCardHeader title="Mai Am Thien Duyen" />
                </Grid>
                <Grid
                    item
                    xs={12}
                    sx={{
                        fontSize: 24,
                        fontFamily: 'times-new-roman',
                        overflow: 'auto',
                    }}
                >
                    Orphanages of Vietnam, with the help of relatives and
                    friends in Vietnam, found an orphanage in a rural area named
                    Mai Am Thien Duyen. The orphanage is not very well known so
                    they don’t have enough support financially. The orphanage
                    was founded in 1988 and currently run by Tran Thi Cam Giang,
                    (aka Ma Muoi).
                    <br />
                    <br />
                    Mai Am Thien Duyen curently cares for 125 kids and adults,
                    some with special needs. In addition to providing food,
                    medical care, and shelter, the orphanage also creates
                    opportunities for the children to attend public school, with
                    40 currently attending school.
                    <br />
                    <br />
                    In order to provide for the children, the orphanage has
                    limited abilities and resources to makes money such as
                    selling lottery tickets and growing their own vegetables.
                    <br />
                    <br />
                    This place needs a tremendous amount of help to maintain
                    their day-to-day activities.
                </Grid>
            </Grid>
            <Grid
                item
                xs={7}
                lg={6}
                container
                justifyContent="end"
                sx={{ height: '100%' }}
            >
                <Grid
                    item
                    xs={12}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        pb: 4,
                    }}
                >
                    <img
                        src={co_so_bao}
                        alt="Orphanage"
                        style={{
                            width: '70%',
                            objectFit: 'contain',
                        }}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <img
                        src={orphans_in_bed}
                        alt="Orphanage"
                        style={{
                            width: '70%',
                            objectFit: 'contain',
                        }}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default OrphanageInfo
