import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import ResponsiveImage from './ResponsiveImage'
import placeholder from '../Resources/Orphans/metal_beds_oprhans.png'
import placeholder2 from '../Resources/Orphans/three_orphans_on_ipad.png'
import placeholder3 from '../Resources/Orphans/metal_beds_of_orphans_2.png'
import placeholder4 from '../Resources/Orphans/red_flower_shirt_orphan.png'

const ImageCarousel = () => {
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 1,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    }
    return (
        <Carousel
            additionalTransfrom={0}
            arrows
            autoPlaySpeed={3000}
            centerMode={true}
            className=""
            containerClass="container-with-dots"
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            pauseOnHover
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={responsive}
            rewind={false}
            rewindWithAnimation={false}
            rtl={false}
            shouldResetAutoplay
            showDots={false}
            sliderClass=""
            slidesToSlide={1}
            swipeable
        >
            <ResponsiveImage label="img" source={placeholder} />
            <ResponsiveImage label="img" source={placeholder2} />
            <ResponsiveImage label="img" source={placeholder3} />
            <ResponsiveImage label="img" source={placeholder4} />
        </Carousel>
    )
}

export default ImageCarousel
