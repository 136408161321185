import OrphanageInfo from './OrphanageInfo'
import OrphangeIntro from './OrphangeIntro'

const AboutOrphangesPage = () => {
    return (
        <>
            <OrphangeIntro />
            <OrphanageInfo />
        </>
    )
}

export default AboutOrphangesPage
