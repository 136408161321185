import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

const ScrollToTopButton = () => {
    const [isVisible, setIsVisible] = useState(false)

    useEffect(() => {
        // Show/hide the button based on scroll position
        const handleScroll = () => {
            const currentScrollY = window.scrollY
            const threshold = 300 // Adjust this value to determine when the button appears

            setIsVisible(currentScrollY > threshold)
        }

        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Smooth scroll behavior
        })
    }

    return (
        <>
            {isVisible && (
                <Button
                    onClick={scrollToTop}
                    variant="contained"
                    color="primary"
                    sx={{
                        position: 'fixed',
                        bottom: '2rem',
                        right: '2rem',
                        zIndex: 1000,
                    }}
                >
                    <KeyboardArrowUpIcon />
                </Button>
            )}
        </>
    )
}

export default ScrollToTopButton
