import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import './fonts.css'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import ScrollToTopOnMount from './Components/ScrollToTopOnMount'
import { PayPalScriptProvider } from '@paypal/react-paypal-js'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <React.StrictMode>
        <PayPalScriptProvider
            options={{
                clientId:
                    'ASOxpZNPbzLBAin3PviHNL1tB2_OT7jxBvOb2krEnquULUUlqsEFqzp1iLpR88ppjdkmwjV5Hc4TL1ZC',
                currency: 'USD',
                components: 'buttons',
            }}
        >
            <BrowserRouter>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <ScrollToTopOnMount />
                    <App />
                </LocalizationProvider>
            </BrowserRouter>
        </PayPalScriptProvider>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

/* paypal sandbox credentials = AdH_k4GGIOZC70ic60vQ-54vXoSRB7YlQM32ltlMDZFXkrfEDG2oBlQ_3AHGFQtudFfYvxVgreSF2KlS */
