import { useMediaQuery, useTheme } from '@mui/material'
import YouTube, { YouTubeEvent } from 'react-youtube'

type PlayerProp = {
    videoId?: string
}
const YoutubePlayer = ({ videoId }: PlayerProp) => {
    const theme = useTheme()
    // Define breakpoints
    const breakpoints = {
        xl: useMediaQuery(theme.breakpoints.only('xl')),
        lg: useMediaQuery(theme.breakpoints.only('lg')),
        md: useMediaQuery(theme.breakpoints.only('md')),
        sm: useMediaQuery(theme.breakpoints.only('sm')),
        xs: useMediaQuery(theme.breakpoints.only('xs')),
    }
    // Calculate dimensions based on breakpoints
    const getHeightAndWidth = () => {
        if (breakpoints.xl) {
            return { height: 500, width: 900 }
        } else if (breakpoints.lg) {
            return { height: 500, width: 700 }
        } else if (breakpoints.md) {
            return { height: 300, width: 500 }
        } else if (breakpoints.sm) {
            return { height: 300, width: 500 }
        } else if (breakpoints.xs) {
            return { height: 250, width: 300 }
        } else {
            // Fallback dimensions
            return { height: 500, width: 700 }
        }
    }

    const { height, width } = getHeightAndWidth()

    const opts = {
        height: height,
        width: width,
        /*    playerVars: {
            autoplay: 1,
        }, */
    }

    const video = videoId || 'sMGf4QKjl9A'

    const onReady = (event: YouTubeEvent<any>) => {
        event.target.pauseVideo()
    }

    return <YouTube videoId={video} opts={opts} onReady={onReady} />
}

export default YoutubePlayer
