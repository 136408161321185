import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import flowerField from '../../Resources/flower-field.jpg'
import IntroPage from '../../Components/IntroPage'

type Props = {
    smDown?: boolean
}
const UpdateIntro = ({ smDown }: Props) => {
    return (
        <Grid container sx={{ height: smDown ? undefined : '100%' }}>
            {smDown ? (
                <>
                    <UpdatesCard smDown={smDown} />
                </>
            ) : (
                <IntroPage image={flowerField} children={<UpdatesCard />} />
            )}
        </Grid>
    )
}

export default UpdateIntro

const UpdatesCard = ({ smDown }: Props) => {
    const size = '450px'
    const theme = useTheme()
    const smOnly = useMediaQuery(theme.breakpoints.only('sm'))
    return (
        <Box
            sx={{
                backgroundColor: 'whitesmoke',
                width: smDown ? (smOnly ? '100%' : undefined) : size,
                height: '400px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center', // Aligns content to the bottom
                alignItems: smOnly ? 'center' : undefined,
                position: 'relative',
                padding: 5,
            }}
        >
            <Typography
                variant="h2"
                sx={{
                    fontFamily: 'Bodoni',
                    fontWeight: 'bold',
                    paddingBottom: 5,
                }}
            >
                Updates
            </Typography>
            <Typography variant="h6" sx={{ fontSize: 20 }}>
                Find the latest updates about events here
            </Typography>
            <br />
            <br />
            <Typography variant="h6" sx={{ fontSize: 20 }}>
                Follow our Twitter: @orphanagesofVN
            </Typography>
            <br />
            <br />
            <Typography variant="h6" sx={{ fontSize: 20 }}>
                Follow our Facebook: Orphanages of Vietnam
            </Typography>
        </Box>
    )
}
