import { useMediaQuery, useTheme } from '@mui/material'
import MissionStatement from './MissionStatement'
import OpeningAbout from './OpeningAbout'
import ValuesPage from './ValuesPage'

/* This is the about us page */
const AboutPage = () => {
    const theme = useTheme()
    const smDown = useMediaQuery(theme.breakpoints.down('md'))
    return (
        <>
            <OpeningAbout smDown={smDown} />
            <MissionStatement smDown={smDown} />
            <ValuesPage smDown={smDown} />
        </>
    )
}

export default AboutPage
