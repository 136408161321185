import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { ThemedRouteButton } from '../../Components/Button'
import blueBackground from '../../Resources/blueBackground.jpg'

const UpdatesPage = () => {
    const theme = useTheme()
    const smMatch = useMediaQuery(theme.breakpoints.down('md'))
    return (
        <Grid
            container
            sx={{
                height: smMatch ? '75%' : '100%',
                backgroundImage: `url(${blueBackground})`,
                backgroundSize: 'cover',
            }}
            alignItems={'center'}
            justifyContent={'center'}
        >
            <Grid
                item
                xs={9}
                lg={6}
                sx={{
                    height: '70%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    backgroundColor: 'whitesmoke',
                    padding: smMatch ? 1 : 2,
                }}
            >
                {smMatch ? (
                    <>
                        <Typography
                            sx={{
                                fontFamily: 'times new roman',
                                fontSize: smMatch ? 25 : undefined,
                            }}
                            variant={smMatch ? undefined : 'h3'}
                        >
                            This Week's Updates
                        </Typography>
                    </>
                ) : (
                    <>
                        <Typography
                            sx={{ fontFamily: 'Bodoni' }}
                            variant={smMatch ? 'h4' : 'h3'}
                        >
                            Updates
                        </Typography>
                        <br />
                        <Typography
                            sx={{ fontFamily: 'times new roman' }}
                            variant={'h5'}
                        >
                            This week's news
                        </Typography>
                    </>
                )}

                <br />
                <Typography
                    sx={{
                        fontFamily: 'times new roman',
                        overflow: 'auto',
                        fontWeight: 'bold',
                        alignItems: 'center',
                    }}
                    variant={'h6'}
                >
                    On February 5th, 2024, $1000 was delivered to Mai Am Thien
                    Duyen. Rice, milk, and additional food was also given.
                </Typography>
                <br />
                <ThemedRouteButton
                    title="More Updates"
                    routePath="updates"
                    width={smMatch ? '60%' : '40%'}
                />
            </Grid>
        </Grid>
    )
}

export default UpdatesPage
