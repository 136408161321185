import { useMediaQuery, useTheme } from '@mui/material'
import DonationInformationPage from './DonationInformationPage'

const DonatePage = () => {
    const theme = useTheme()
    const smDown = useMediaQuery(theme.breakpoints.down('md'))
    /*  const largeUp = useMediaQuery(theme.breakpoints.up('md')) */
    return (
        <>
            <DonationInformationPage smDown={smDown} />
        </>
    )
}

export default DonatePage
