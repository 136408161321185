import React, { ReactNode } from 'react'
import TopBar from './AppBar'
import Footer from './Footer'
import { Grid, useMediaQuery, useTheme } from '@mui/material'
import ScrollToTopButton from './ScrollToTopButton'

interface LayoutProps {
    children: ReactNode // Specify children prop
}

const Layout: React.FC<LayoutProps> = ({ children }: LayoutProps) => {
    const theme = useTheme()
    const smDown = useMediaQuery(theme.breakpoints.down('md'))
    const onlySm = useMediaQuery(theme.breakpoints.only('sm'))
    return (
        <>
            <TopBar />
            <main
                style={{
                    marginTop: smDown ? 64 : 144,
                    position: smDown ? undefined : 'relative',
                }}
            >
                <Grid
                    container
                    style={{
                        height: onlySm
                            ? 'calc(100vh - 64px)'
                            : 'calc(100vh - 144px)',
                        position: smDown ? undefined : 'relative',
                    }}
                >
                    {children}
                    <Footer />
                </Grid>
                <ScrollToTopButton />
            </main>
        </>
    )
}

export default Layout
