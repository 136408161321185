import { useMediaQuery, useTheme } from '@mui/material'
import CreationPage from './CreationPage'
import Facts from './Facts'
import PhotoBio from './PhotoBio'

const AboutFounder = () => {
    const theme = useTheme()
    const smDown = useMediaQuery(theme.breakpoints.down('md'))
    const xsOnly = useMediaQuery(theme.breakpoints.down('sm'))
    const mdOnly = useMediaQuery(theme.breakpoints.only('md'))
    return (
        <>
            <PhotoBio smDown={smDown} xsOnly={xsOnly} mdOnly={mdOnly} />
            <CreationPage smDown={smDown} />
            <Facts smDown={smDown} />
        </>
    )
}

export default AboutFounder
