import { Box, Grid, Typography } from '@mui/material'
import { ThemedRouteButton } from '../../../Components/Button'
import flowerField from '../../../Resources/flower-field.jpg'
import IntroPage from '../../../Components/IntroPage'

type Prop = {
    smDown?: boolean
}
const OpeningAbout = ({ smDown }: Prop) => {
    return (
        <Grid container sx={{ height: smDown ? undefined : '100%' }}>
            <IntroPage
                image={flowerField}
                children={<AboutUsCard smDown={smDown} />}
            />
        </Grid>
    )
}

export default OpeningAbout

type AboutUsCardProp = {
    smDown?: boolean
}

const AboutUsCard = ({ smDown }: AboutUsCardProp) => {
    const size = '450px'
    return (
        <Box
            sx={{
                backgroundColor: 'whitesmoke',
                width: smDown ? undefined : size,
                height: smDown ? '300px' : '400px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end', // Aligns content to the bottom
                alignItems: smDown ? 'center' : null,
                position: 'relative',
                padding: smDown ? 3 : 5,
            }}
        >
            <Typography
                variant={smDown ? 'h3' : 'h2'}
                sx={{
                    fontFamily: 'Bodoni',
                    fontWeight: 'bold',
                    paddingBottom: 5,
                    paddingTop: smDown ? 4 : null,
                }}
            >
                About Us
            </Typography>
            <Typography
                variant="h6"
                sx={{
                    fontFamily: 'times-new-roman',
                    fontSize: smDown ? 22 : 28,
                    textAlign: smDown ? 'center' : undefined,
                    overflow: 'auto',
                }}
            >
                We strive to help the underprivileged children in Vietnam by
                raising money to improve their lives.
            </Typography>
            <div style={{ marginTop: 'auto', marginBottom: '10px' }}>
                <ThemedRouteButton
                    title="Learn more about the founder"
                    routePath="/about/founder"
                />
            </div>
        </Box>
    )
}
