import { CardHeader } from '@mui/material'

type CardHeaderProp = {
    title: string
    fontSize?: number
}
export const GenericCardHeader = ({ title, fontSize }: CardHeaderProp) => (
    <CardHeader
        title={title}
        titleTypographyProps={{
            sx: {
                fontFamily: 'Bodoni',
                fontSize: fontSize || 35,
                fontWeight: 'bold',
                display: 'flex',
                justifyContent: 'center',
            },
        }}
    />
)
