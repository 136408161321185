import { Grid, useMediaQuery, useTheme } from '@mui/material'
import { ReactNode } from 'react'

type Props = {
    image: string
    children: ReactNode
    home?: boolean
}
const IntroPage = ({ image, children, home }: Props) => {
    const theme = useTheme()
    const smMatch = useMediaQuery(theme.breakpoints.down('md'))
    const onlySm = useMediaQuery(theme.breakpoints.only('sm'))
    return (
        <Grid
            container
            sx={{
                height: smMatch ? (onlySm ? '100%' : '55%') : '100%',
            }}
        >
            <Grid
                item
                xs={12}
                sx={{
                    height: onlySm ? '100%' : undefined,
                    backgroundImage: `url(${image})`,
                    backgroundSize: 'cover',
                }}
                container
            >
                <Grid
                    item
                    xs={12}
                    md={home ? 10 : 12}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: smMatch
                            ? 'center'
                            : home
                            ? 'flex-end'
                            : 'center',
                    }}
                >
                    {children}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default IntroPage
