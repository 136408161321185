import {
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { ThemedRouteButton } from '../../Components/Button'

import Racheal from '../../Resources/Racheal/Rachael_Current.png'

const AbouttheFounder = () => {
    const theme = useTheme()
    const smMatch = useMediaQuery(theme.breakpoints.down('md'))
    const onlyXs = useMediaQuery(theme.breakpoints.only('xs'))
    return (
        <Grid
            container
            sx={{
                margin: 4,
                mt: 10,
                mb: 10,
                pt: onlyXs ? 2 : 0,
                height: onlyXs ? '100%' : undefined,
            }}
        >
            <Grid
                item
                xs={12}
                sm={5}
                lg={6}
                container
                alignItems={'center'}
                justifyContent={onlyXs ? 'center' : 'flex-end'}
            >
                <img
                    src={Racheal}
                    alt="Racheal"
                    style={{
                        width: '70%',
                        objectFit: 'contain',
                    }}
                />
            </Grid>

            <Grid
                item
                xs={12}
                sm={7}
                lg={6}
                container
                alignItems={'center'}
                justifyContent={'flex-start'}
            >
                <FounderCard smMatch={smMatch} />
            </Grid>
        </Grid>
    )
}

export default AbouttheFounder

type Prop = {
    smMatch: boolean
}
const FounderCard = ({ smMatch }: Prop) => {
    return (
        <>
            <Grid item xs={12}>
                {smMatch ? null : <Divider color="black" />}
                <div style={{ margin: 20 }}>
                    <Card elevation={0}>
                        <CardHeader
                            title="About the founder"
                            titleTypographyProps={{
                                sx: {
                                    fontFamily: 'Bodoni',
                                    fontSize: smMatch ? 30 : 45,
                                },
                            }}
                        />
                        <CardContent
                            sx={{
                                fontFamily: smMatch
                                    ? 'times new roman'
                                    : 'times new roman',
                                fontSize: smMatch ? 20 : 25,
                            }}
                        >
                            Rachael Thai is a compassionate teenager who is
                            eager about helping children in orphanages in
                            Vietnam.
                        </CardContent>
                    </Card>
                    <ThemedRouteButton
                        title="More about Rachael"
                        routePath="about/founder"
                        width={smMatch ? undefined : '60%'}
                    />
                </div>
            </Grid>
        </>
    )
}
