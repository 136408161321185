import { PayPalButtons } from '@paypal/react-paypal-js'
import { CreateOrderActions, CreateOrderData } from '@paypal/paypal-js'

type PayButton = {
    amount: number
}

const NewPayPalButton = ({ amount }: PayButton) => {
    return (
        <PayPalButtons
            createOrder={(
                data: CreateOrderData,
                actions: CreateOrderActions
            ) => {
                return actions.order.create({
                    intent: 'CAPTURE',
                    purchase_units: [
                        {
                            reference_id: 'VLUJ42NBQFBP8',
                            amount: {
                                value: amount.toFixed(2),
                                currency_code: 'USD',
                            },
                        },
                    ],
                })
            }}
            onApprove={(data, actions) => {
                if (actions.order) {
                    return actions.order.capture().then((details) => {
                        alert('Transaction completed! Thank You!')
                    })
                } else {
                    return Promise.resolve()
                }
            }}
        />
    )
}

export default NewPayPalButton

export const DonationButton = ({ amount }: PayButton) => {
    return (
        <PayPalButtons
            createOrder={(
                data: CreateOrderData,
                actions: CreateOrderActions
            ) => {
                return actions.order.create({
                    intent: 'CAPTURE',
                    purchase_units: [
                        {
                            reference_id: 'donation',
                            amount: {
                                value: amount.toFixed(2),
                                currency_code: 'USD',
                            },
                        },
                    ],
                })
            }}
            onApprove={(data, actions) => {
                if (actions.order) {
                    return actions.order.capture().then((details) => {
                        alert(
                            'Donation completed! Thank you for your generosity!'
                        )
                    })
                } else {
                    return Promise.resolve()
                }
            }}
        />
    )
}
