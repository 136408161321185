import { Grid, Typography } from '@mui/material'
import { primaryColor } from '../../Components/pallete'
import { ThemedRouteButton } from '../../Components/Button'
import { Favorite, FavoriteBorder } from '@mui/icons-material'
import vietnamBoat from '../../Resources/vietnam-boat.jpg'

const ThankYouPage = () => {
    return (
        <Grid
            container
            justifyContent={'center'}
            alignItems={'center'}
            sx={{ height: '100%', backgroundImage: `url(${vietnamBoat})` }}
        >
            <Grid
                item
                xs={6}
                sx={{
                    textAlign: 'center',
                    backgroundColor: 'whitesmoke',
                    padding: 4,
                }}
            >
                <FavoriteBorder fontSize="large" />
                <Typography
                    variant="h2"
                    sx={{
                        fontFamily: '"Times New Roman", Times, serif',
                        mb: 10,
                    }}
                >
                    Thank You!
                </Typography>
                <Typography
                    variant="h5"
                    sx={{
                        fontFamily: '"Times New Roman", Times, serif',
                        mb: 4,
                    }}
                >
                    We are deeply grateful for your contribution. Your
                    generosity is truly appreciated and will go a long way in
                    supporting our mission.
                </Typography>
                <>
                    <ThemedRouteButton title="Home" routePath="/" width="50%" />
                    <ThemedRouteButton
                        title="About the Orphans"
                        routePath="about/orphans"
                        width="50%"
                    />
                </>
            </Grid>
        </Grid>
    )
}

export default ThankYouPage
