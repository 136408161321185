import { Grid } from '@mui/material'
import { ThemedRouteButton } from '../../../Components/Button'

type DividerCardsProp = {
    smDown: boolean
    label: string
    buttonLabel: string
    route: string
    borderTop?: boolean
}
const DividerCards = ({
    smDown,
    buttonLabel,
    label,
    route,
    borderTop,
}: DividerCardsProp) => {
    return (
        <Grid
            item
            xs={smDown ? 9 : undefined}
            md
            sx={{
                backgroundColor: '#DDE4E6',
                height: smDown ? undefined : '100%',
                borderTop: smDown && borderTop ? 1 : 0,
            }}
            container
            justifyContent={'center'}
            alignItems={'center'}
            direction={smDown ? 'row' : 'column'}
        >
            <Grid
                item
                xs={12}
                sm={12}
                md={2}
                sx={{
                    fontFamily: 'Bodoni',
                    fontSize: smDown ? 24 : 30,
                    paddingTop: smDown ? 4 : 0,
                    paddingBottom: smDown ? 2 : 3,
                    textAlign: smDown ? 'center' : undefined,
                }}
            >
                {label}
            </Grid>
            <Grid item sx={{ paddingBottom: smDown ? 2 : 0 }}>
                <ThemedRouteButton
                    title={buttonLabel}
                    routePath={route}
                    color="whitesmoke"
                />
            </Grid>
        </Grid>
    )
}

export default DividerCards
