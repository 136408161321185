import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { ThemedRouteButton } from '../../Components/Button'

const TotalDonations = () => {
    const theme = useTheme()
    const smMatch = useMediaQuery(theme.breakpoints.down('md'))
    const onlySm = useMediaQuery(theme.breakpoints.only('sm'))
    return (
        <Grid
            container
            sx={{
                height: smMatch ? (onlySm ? '100%' : '60%') : '100%',
            }}
            alignItems={'center'}
        >
            <Grid
                item
                xs={12}
                sx={{
                    height: smMatch ? '100%' : '60%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    backgroundColor: '#DDE4E6',
                }}
            >
                <Typography
                    sx={{
                        fontFamily: 'Bodoni',
                        fontStyle: 'italic',
                        fontSize: smMatch ? (onlySm ? 60 : 40) : undefined,
                    }}
                    variant={smMatch ? undefined : 'h2'}
                >
                    Donation Status
                </Typography>
                <br />
                <Typography sx={{ fontFamily: 'sans-serif' }} variant={'h6'}>
                    So far, we have collected...
                </Typography>
                <br />
                <Typography
                    sx={{
                        fontFamily: 'Bodoni',
                        overflow: 'auto',
                        padding: onlySm ? 2 : 4,
                        backgroundColor: 'white',
                    }}
                    variant={onlySm ? 'h5' : 'h3'}
                >
                    $1,000
                </Typography>
                <br />
                <ThemedRouteButton
                    title="Get Updates"
                    routePath="updates"
                    width={smMatch ? '72%' : '20%'}
                    color="whitesmoke"
                />
            </Grid>
        </Grid>
    )
}

export default TotalDonations
