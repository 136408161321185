import { AppBar, useMediaQuery, useTheme } from '@mui/material'
import SiteNavigationBar from './SiteNavigationBar'
import SocialNavigationBar from './SocialNavigationBar'
import SmallNavigationBar from './SmallNavigationBar'

const TopBar = () => {
    const theme = useTheme()
    const smDown = useMediaQuery(theme.breakpoints.down('md'))

    return (
        <>
            <AppBar
                position={'fixed'}
                sx={{
                    boxShadow: 'none',
                    borderBottom: '1px solid #e0e0e0',
                    height: smDown ? 50 : (theme) => theme.spacing(18),
                    color: 'white',
                    backgroundColor: 'white',
                }}
            >
                {smDown ? (
                    <SmallNavigationBar />
                ) : (
                    <>
                        <SocialNavigationBar />
                        <SiteNavigationBar />
                    </>
                )}
            </AppBar>
        </>
    )
}

export default TopBar
