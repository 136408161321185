import { Grid } from '@mui/material'
import { primaryColor } from '../../../Components/pallete'
import IntroCard from './Components/IntroCard'
import { useThemeBreakPoints } from '../../../Functions/useBreakPointDebugger'

const OrphangeIntro = () => {
    const { smMatch } = useThemeBreakPoints()
    return (
        <Grid
            container
            sx={{ backgroundColor: primaryColor }}
            justifyContent="center"
            alignItems="center"
        >
            <Grid
                item
                xs={12}
                md={6}
                xl={4}
                sx={{ pt: 2, pb: 2, pl: smMatch ? 2 : 0, pr: smMatch ? 2 : 0 }}
            >
                <IntroCard />
            </Grid>
        </Grid>
    )
}

export default OrphangeIntro
