import {
    Button,
    Card,
    CardHeader,
    Grid,
    Link,
    List,
    ListItem,
    ListItemButton,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import logo from '../../Resources/newLogo.png'
import { useNavigate } from 'react-router-dom'
import { X, Facebook, Instagram } from '@mui/icons-material'
import useBreakPointDebugger from '../../Functions/useBreakPointDebugger'

export const LogoCard = () => {
    const theme = useTheme()
    const smDown = useMediaQuery(theme.breakpoints.down('md'))

    /*     const handleEmailClick = () => {
        window.location.href = 'mailto:recipient@example.com'
    } */

    return (
        <Card
            sx={{
                backgroundColor: '#DDE4E6',
                paddingBottom: smDown ? 2 : 0,
            }}
            elevation={0}
        >
            <Grid container alignItems="center">
                <Grid item xs={4} sm={2} md={6}>
                    <img src={logo} alt="Orphanges Of Vietnam" height={120} />
                </Grid>
                <Grid
                    item
                    xs={5}
                    sx={{
                        overflowWrap: 'break-word',
                        fontFamily: 'Bodoni',
                        fontSize: 20,
                        fontWeight: 'bold',
                    }}
                >
                    Orphanages of Vietnam
                </Grid>
            </Grid>

            {/*             <Grid container>
                <Grid item xs={12}>
                    <Button
                        sx={{
                            justifyContent: 'center',
                            width: '100%',
                            paddingBottom: 1,
                            backgroundColor: 'whitesmoke',
                            fontFamily: 'Bodoni',
                            color: 'black',
                            '&:hover': {
                                backgroundColor: 'white',
                                color: '#3c52b2',
                            },
                        }}
                        onClick={handleEmailClick}
                    >
                        Contact Us
                    </Button>
                </Grid>
            </Grid> */}
        </Card>
    )
}

export const AboutCard = () => {
    const navigate = useNavigate()

    const handleRoute = (route: string) => {
        navigate(`${route}`)
    }
    return (
        <Grid container justifyContent={'center'} alignItems={'center'}>
            <Grid item>
                <Card
                    sx={{
                        backgroundColor: '#DDE4E6',
                    }}
                    elevation={0}
                >
                    <CardHeader
                        title="About"
                        sx={{ paddingBottom: 0, paddingLeft: 9 }}
                        titleTypographyProps={{
                            sx: {
                                fontFamily: 'Bodoni',
                                fontWeight: 'bold',
                            },
                        }}
                    />
                    <List sx={{ paddingTop: 0 }}>
                        <ListItem>
                            <ListItemButton
                                sx={{
                                    paddingTop: 0,
                                    fontFamily: 'Bodoni',
                                    fontSize: 18,
                                }}
                                onClick={() => handleRoute('about/us')}
                            >
                                About Us
                            </ListItemButton>
                        </ListItem>
                        <ListItem sx={{ paddingTop: 0 }}>
                            <ListItemButton
                                sx={{
                                    paddingTop: 0,
                                    fontFamily: 'Bodoni',
                                    fontSize: 18,
                                }}
                                onClick={() => handleRoute('about/founder')}
                            >
                                About the Founder
                            </ListItemButton>
                        </ListItem>
                        <ListItem sx={{ paddingTop: 0 }}>
                            <ListItemButton
                                sx={{
                                    paddingTop: 0,
                                    fontFamily: 'Bodoni',
                                    fontSize: 18,
                                }}
                                onClick={() => handleRoute('about/orphans')}
                            >
                                About the Orphans
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Card>
            </Grid>
        </Grid>
    )
}

// Possible swap for this card all the different locations of the orphanages
export const ContactCard = () => {
    return (
        <Card
            sx={{
                backgroundColor: '#DDE4E6',
            }}
            elevation={0}
        >
            <CardHeader
                title="Follow Us:"
                sx={{ paddingBottom: 0, paddingLeft: 4 }}
                titleTypographyProps={{
                    sx: { fontFamily: 'Bodoni', fontWeight: 'bold' },
                }}
            />
            <Grid
                container
                sx={{ paddingTop: 3, paddingLeft: 4 }}
                justifyContent="flex-start"
                alignItems="center"
            >
                <Grid item sx={{ paddingRight: 4 }}>
                    <Link href="https://twitter.com/?lang=en">
                        <X fontSize="large" sx={{ color: 'black' }} />
                    </Link>
                </Grid>
                <Grid item sx={{ paddingRight: 4 }}>
                    <Link href="https://www.facebook.com/">
                        <Facebook fontSize="large" sx={{ color: 'black' }} />
                    </Link>
                </Grid>
                <Grid item>
                    <Link href="https://www.instagram.com/">
                        <Instagram fontSize="large" sx={{ color: 'black' }} />
                    </Link>
                </Grid>
            </Grid>
        </Card>
    )
}
