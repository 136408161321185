import AbouttheFounder from './AbouttheFounder'
import ImagePage from './ImagePage'
import OverViewPage from './OverviewPage'
import TotalDonations from './TotalDonations'
import UpdatesPage from './UpdatesPage'

const HomePage = () => {
    return (
        <>
            <ImagePage />
            <OverViewPage />
            <AbouttheFounder />
            <UpdatesPage />
            <TotalDonations />
        </>
    )
}

export default HomePage
