import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

/* 
This function is used to scroll to the top of the page when routing to a page
*/
const ScrollToTopOnMount = () => {
    const { pathname } = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])

    return null
}

export default ScrollToTopOnMount
