import { useMediaQuery, useTheme } from '@mui/material'
import SocialPage from './SocialPage'
import UpdateIntro from './UpdateIntro'
import UpcomingEvents from './UpcomingEvents'

const UpdatesPage = () => {
    const theme = useTheme()
    const smDown = useMediaQuery(theme.breakpoints.down('md'))
    return (
        <>
            <UpdateIntro smDown={smDown} />
            <SocialPage smDown={smDown} />
            <UpcomingEvents smDown={smDown} />
        </>
    )
}

export default UpdatesPage
