import {
    Card,
    CardContent,
    CardHeader,
    Grid,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import littleBoy from '../../Resources/Orphans/orange_shirt_orphan.png'
import { ThemedRouteButton } from '../../Components/Button'
import { primaryColor } from '../../Components/pallete'
import { useThemeBreakPoints } from '../../Functions/useBreakPointDebugger'

const ImagePage = () => {
    const theme = useTheme()
    const smMatch = useMediaQuery(theme.breakpoints.down('md'))
    const onlySm = useMediaQuery(theme.breakpoints.only('sm'))
    const onlyMd = useMediaQuery(theme.breakpoints.only('md'))
    const lgUp = useMediaQuery(theme.breakpoints.up('lg'))
    const onlyXs = useMediaQuery(theme.breakpoints.only('xs'))

    const cardSize = smMatch ? undefined : '460px'
    const cardHeight = smMatch ? undefined : '300px'
    return (
        <Grid
            container
            sx={{
                height: onlyXs ? undefined : '100%',
                mb: onlyXs ? 2 : 0,
            }}
        >
            {onlyXs ? (
                <>
                    <Grid
                        item
                        xs={12}
                        sx={{
                            height: 300,
                            backgroundImage: `url(${littleBoy})`,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundColor: primaryColor,
                            backgroundPosition: onlyMd
                                ? 'right 1px center'
                                : undefined,
                        }}
                        container
                        justifyContent={'flex-end'}
                        alignItems={'flex-end'}
                    ></Grid>
                    <Grid
                        item
                        xs={12}
                        sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                        }}
                    >
                        <OpeningCard
                            smMatch={smMatch}
                            cardHeight={cardHeight}
                            cardSize={cardSize}
                        />
                    </Grid>
                </>
            ) : (
                <Grid
                    item
                    xs={12}
                    sx={{
                        height: onlySm ? '100%' : undefined,
                        backgroundImage: `url(${littleBoy})`,
                        backgroundSize: lgUp ? 'cover' : 'fit',
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: primaryColor,
                        backgroundPosition: onlyMd
                            ? 'right 1px center'
                            : undefined,
                    }}
                    container
                >
                    <Grid
                        item
                        xs={12}
                        md={10}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: smMatch ? 'center' : 'flex-end',
                        }}
                    >
                        <OpeningCard
                            smMatch={smMatch}
                            cardHeight={cardHeight}
                            cardSize={cardSize}
                        />
                    </Grid>
                </Grid>
            )}
        </Grid>
    )
}

export default ImagePage

type CardProp = {
    smMatch: boolean
    cardHeight?: string
    cardSize?: string
}
const OpeningCard = ({ smMatch, cardHeight, cardSize }: CardProp) => {
    const { xsMatch } = useThemeBreakPoints()
    return (
        <>
            {xsMatch ? (
                <Grid
                    container
                    justifyContent={'center'}
                    /* sx={{ backgroundColor: primaryColor }} */
                >
                    <Grid
                        item
                        xs={12}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Typography variant="h4" fontFamily={'Bodoni'}>
                            Your Help, Helps Others
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <ThemedRouteButton title="Donate" routePath="donate" />
                    </Grid>
                </Grid>
            ) : (
                <Card
                    sx={{
                        height: cardHeight,
                        width: cardSize,
                        backgroundColor: 'rgba(255, 255, 255, 0.85)', // White color with 80% opacity
                        textAlign: 'center',
                        margin: smMatch ? 2 : undefined,
                    }}
                    elevation={0}
                >
                    <CardHeader
                        title={
                            <>
                                Your help,
                                <br />
                                Helps Others
                            </>
                        }
                        titleTypographyProps={{
                            variant: smMatch ? 'h3' : 'h2',
                            sx: {
                                overflowWrap: 'break-word',
                                fontFamily: 'Canva Sans',
                                textTransform: 'capitalize',
                                paddingTop: 4,
                                fontWeight: 'bold',
                            },
                        }}
                    />
                    <CardContent>
                        <Grid container justifyContent={'center'}>
                            <Grid item xs={6}>
                                <ThemedRouteButton
                                    title="Donate"
                                    routePath="donate"
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            )}
        </>
    )
}
