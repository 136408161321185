import { Grid, Typography } from '@mui/material'
import ImageCarousel from '../../../Components/Carousel'

const InformationPage = () => {
    return (
        <Grid container>
            <Grid
                item
                xs={12}
                sx={{
                    padding: 10,
                    fontSize: 20,
                    overflow: 'auto',
                }}
            >
                <Typography
                    variant="h6"
                    sx={{ fontFamily: 'times-new-roman', fontSize: 22 }}
                >
                    At Mai Am Thien Duyen, there are 125 kids and adults. 82
                    orphans out of 125 have different types of brain damage such
                    as cerebral palsy, a neurological condition that can present
                    as issues with muscle tone, posture and/or a movement
                    disorder. Some of the adults at the orphanage have been
                    there since they were children, and lived most of their
                    life, if not all, at the orphanage. Some of the adults were
                    able to attend medical school and become doctors and others
                    were able to get married and live normal lives.
                </Typography>

                <br />
                <br />
                <Typography
                    variant="h6"
                    sx={{ fontFamily: 'times-new-roman', fontSize: 22 }}
                >
                    The orphanage is strugging to meet the needs of the disabled
                    orphans, because they does not have enough money and
                    resources to provide desirable care for all of the children.
                    The children do not have enough clothing or food during
                    times of hardship, nor do they have mattresses to sleep on.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <ImageCarousel />
            </Grid>
        </Grid>
    )
}

export default InformationPage
