import { Card, CardActions, CardContent, CardHeader, Grid } from '@mui/material'
import peaceSign from '../../Resources/Orphans/peace_sign_orphan.png'
import DonateForm from './DonateForm'
import ResponsiveImage, { CoverImage } from '../../Components/ResponsiveImage'
import PaypalLinkButton from './PaypalLinkButton'

type Props = {
    smDown?: boolean
}
const DonationInformationPage = ({ smDown }: Props) => {
    return (
        <Grid
            container
            sx={{
                height: smDown ? undefined : '100%',
                backgroundColor: '#DDE4E6',
            }}
            justifyContent={'center'}
            alignItems={'center'}
        >
            <Grid
                item
                xs={12}
                container
                sx={{ height: smDown ? undefined : '100%' }}
                direction={smDown ? 'column-reverse' : undefined}
            >
                <Grid item xs={12} md={6} sx={{ paddingRight: smDown ? 0 : 0 }}>
                    <ExplanationCard2 smDown={smDown} />
                </Grid>
                <Grid item xs={12} md={6} container justifyContent={'center'}>
                    {smDown ? (
                        <Grid item>
                            <ResponsiveImage
                                label="peace sign"
                                source={peaceSign}
                            />
                        </Grid>
                    ) : (
                        <CoverImage label="peace sign" source={peaceSign} />
                    )}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default DonationInformationPage

const ExplanationCard = ({ smDown }: Props) => {
    return (
        <Card
            sx={{
                height: smDown ? undefined : '100%',
                display: 'flex',
                flexDirection: 'column',
                overflowA: 'auto',
            }}
            elevation={0}
        >
            <CardHeader
                title={'Give a gift that helps'}
                titleTypographyProps={{
                    sx: {
                        fontFamily: 'Bodoni',
                        fontSize: 35,
                        fontWeight: 'bold',
                    },
                }}
            />
            <CardContent>
                <DonateForm smDown={smDown} />
            </CardContent>
        </Card>
    )
}

const ExplanationCard2 = ({ smDown }: Props) => {
    return (
        <Card
            sx={{
                height: smDown ? undefined : '100%',
                display: 'flex',
                flexDirection: 'column',
                overflowA: 'auto',
            }}
            elevation={0}
        >
            <CardHeader
                title={'Give a gift that helps'}
                titleTypographyProps={{
                    sx: {
                        fontFamily: 'Bodoni',
                        fontSize: 35,
                        fontWeight: 'bold',
                    },
                }}
            />
            <CardContent sx={{ fontFamily: 'times-new-roman', fontSize: 26 }}>
                Thank you for visiting our donation website and supporting
                orphanages in Vietnam. Your generosity makes a significant
                impact on the lives of children in need. We deeply appreciate
                your willingness to contribute to their well-being and future.
                To make a donation, please click the button below to visit our
                PayPal donation page.
            </CardContent>
            <CardActions>
                <PaypalLinkButton />
            </CardActions>
        </Card>
    )
}
