import { Button } from '@mui/material'

const PaypalLinkButton = () => {
    return (
        <Button
            href="https://www.paypal.com/donate/?hosted_button_id=VLUJ42NBQFBP8"
            variant="contained"
            fullWidth
            sx={{ height: 50, fontFamily: 'Bodoni', fontSize: 20 }}
        >
            Donate here
        </Button>
    )
}

export default PaypalLinkButton
