import { ExpandLess, ExpandMore, Menu } from '@mui/icons-material'
import {
    Collapse,
    Drawer,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Toolbar,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const SmallNavigationBar = () => {
    const theme = useTheme()
    const onlySm = useMediaQuery(theme.breakpoints.only('sm'))

    const [menuOpen, setMenuOpen] = useState(false)
    /* const [socialOpen, setSocialOpen] = useState(false) */

    const toggleMenu = () => {
        setMenuOpen(!menuOpen)
    }

    /*     const toggleSocialMenu = () => {
        setSocialOpen(!socialOpen)
    } */
    return (
        <>
            <Toolbar
                sx={{
                    backgroundColor: '#DDE4E6',
                    color: 'black',
                    height: 50,
                    borderBottom: '1px solid white',
                }}
            >
                <Grid container>
                    <Grid
                        item
                        xs={2}
                        sm={1}
                        sx={{
                            paddingLeft: onlySm ? 0 : 2,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                        }}
                    >
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={toggleMenu}
                        >
                            <Menu />
                        </IconButton>
                    </Grid>
                    <Grid
                        item
                        xs={10}
                        sm={11}
                        sx={{
                            display: onlySm ? 'flex' : undefined,
                            justifyContent: onlySm ? 'flex-start' : 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Bodoni, serif',
                                fontStyle: 'italic',
                                paddingTop: 0.5,
                                fontSize: onlySm ? 35 : 23,
                            }}
                        >
                            Orphanages of Vietnam
                        </Typography>
                    </Grid>
                </Grid>
            </Toolbar>
            <Drawer anchor="bottom" open={menuOpen} onClose={toggleMenu}>
                <NavigationMenu toggleMenu={toggleMenu} />
            </Drawer>
        </>
    )
}

export default SmallNavigationBar

type NavMenu = {
    toggleMenu: () => void
}
const NavigationMenu = ({ toggleMenu }: NavMenu) => {
    const navigate = useNavigate()

    const handleRoute = (route: string) => {
        navigate(`${route}`)
        toggleMenu()
    }

    return (
        <List>
            <ListItem key={1} onClick={() => handleRoute('/')}>
                <ListItemText primary="Home" />
            </ListItem>
            <ListItem key={2} onClick={() => handleRoute('/donate')}>
                <ListItemText primary="Donate" />
            </ListItem>

            <AboutNavigation handleRoute={handleRoute} />

            {/*             <ListItem onClick={() => handleRoute('/volunteer')}>
                <ListItemText primary="Volunteer" />
            </ListItem> */}
            <ListItem key={4} onClick={() => handleRoute('/updates')}>
                <ListItemText primary="Updates" />
            </ListItem>
            {/* <UpdatesNavigation handleRoute={handleRoute} /> */}
            {/*             <ListItem onClick={() => handleRoute('/health')}>
                <ListItemText primary="Health" />
            </ListItem> */}
        </List>
    )
}

type NavigationProp = {
    handleRoute: (route: string) => void
}
const AboutNavigation = ({ handleRoute }: NavigationProp) => {
    const [open, setOpen] = useState(false)

    const handleClick = () => {
        setOpen(!open)
    }

    return (
        <>
            <ListItem key={3} onClick={() => handleClick()}>
                <ListItemText primary="About" />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItemButton
                        sx={{ pl: 4 }}
                        onClick={() => handleRoute('/about/us')}
                    >
                        <ListItemText primary="About Us" />
                    </ListItemButton>
                    <ListItemButton
                        sx={{ pl: 4 }}
                        onClick={() => handleRoute('/about/founder')}
                    >
                        <ListItemText primary="About The Founder" />
                    </ListItemButton>
                    <ListItemButton
                        sx={{ pl: 4 }}
                        onClick={() => handleRoute('/about/orphans')}
                    >
                        <ListItemText primary="About The Orphans" />
                    </ListItemButton>
                    <ListItemButton
                        sx={{ pl: 4 }}
                        onClick={() => handleRoute('/about/orphanages')}
                    >
                        <ListItemText primary="About The Orphanages" />
                    </ListItemButton>
                </List>
            </Collapse>
        </>
    )
}
