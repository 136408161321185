import { ArrowDropDown } from '@mui/icons-material'
import { Button, Grid, Toolbar, Typography } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import NavigationMenu from './NavigationMenu'
import { useState } from 'react'
import { NavigationItem } from './ComponentType'

const SiteNavigationBar = () => {
    return (
        <Toolbar
            sx={{
                backgroundColor: 'white',
                color: 'black',
                height: 100,
                width: '100%',
            }}
            disableGutters
        >
            <Grid
                container
                sx={{ height: '100%', borderTop: 1, borderBottom: 1 }}
                alignItems={'center'}
            >
                <Grid
                    item
                    xs={4}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                    }}
                >
                    <LeftCard />
                </Grid>
                <Grid
                    item
                    xs={4}
                    sx={{
                        color: 'black',
                        borderColor: 'black',
                        borderLeft: 1,
                        borderRight: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                    }}
                >
                    <TitleCard />
                </Grid>
                <Grid
                    item
                    xs={4}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                    }}
                >
                    <RightCard />
                </Grid>
            </Grid>
        </Toolbar>
    )
}

export default SiteNavigationBar

const LeftCard = () => {
    const navigate = useNavigate()

    const handleRoute = (route: string) => {
        navigate(`${route}`)
    }

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const aboutData: NavigationItem[] = [
        { label: 'About Us', route: 'about/us' },
        { label: 'About the Founder', route: 'about/founder' },
        { label: 'About the Orphans', route: 'about/orphans' },
        { label: 'About the Orphanages', route: 'about/orphanages' },
    ]
    return (
        <Grid
            container
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
            }}
        >
            <Grid
                item
                xs={6}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Button
                    sx={{ color: 'black', fontFamily: 'Bodoni', fontSize: 18 }}
                    onClick={() => handleRoute('/')}
                >
                    Home
                </Button>
            </Grid>
            <Grid
                item
                xs={6}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Button
                    sx={{ color: 'black', fontFamily: 'Bodoni', fontSize: 18 }}
                    onClick={handleClick}
                    endIcon={<ArrowDropDown />}
                >
                    About
                </Button>
                <NavigationMenu
                    open={open}
                    handleClose={handleClose}
                    anchorEl={anchorEl}
                    data={aboutData}
                />
            </Grid>
        </Grid>
    )
}

const TitleCard = () => {
    const navigate = useNavigate()
    const location = useLocation()

    const isHome = location.pathname === '/'

    const handleRoute = () => {
        navigate('/')
    }
    return (
        <Typography
            sx={{
                fontFamily: 'Bodoni, serif',
                fontStyle: 'italic',
                fontSize: 'calc(1vw + 1vh + 0.5vmin)',
                height: '100%',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: isHome ? undefined : 'pointer',
            }}
            onClick={handleRoute}
        >
            Orphanages of Vietnam
        </Typography>
    )
}

const RightCard = () => {
    const navigate = useNavigate()

    const handleRoute = (route: string) => {
        navigate(`${route}`)
    }
    return (
        <Grid
            container
            sx={{
                color: 'black',
                borderColor: 'black',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
            }}
        >
            <Grid
                item
                xs={6}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Button
                    sx={{ color: 'black', fontFamily: 'Bodoni', fontSize: 18 }}
                    onClick={() => handleRoute('donate')}
                >
                    Donate
                </Button>
            </Grid>
            <Grid
                item
                xs={6}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Button
                    sx={{ color: 'black', fontFamily: 'Bodoni', fontSize: 18 }}
                    onClick={() => handleRoute('updates')}
                >
                    Updates
                </Button>
            </Grid>
        </Grid>
    )
}
