import { useMediaQuery, useTheme } from '@mui/material'

const useBreakPointDebugger = () => {
    const theme = useTheme()

    const xsMatch = useMediaQuery(theme.breakpoints.only('xs'))
    const smMatch = useMediaQuery(theme.breakpoints.only('sm'))
    const mdMatch = useMediaQuery(theme.breakpoints.only('md'))
    const lgMatch = useMediaQuery(theme.breakpoints.only('lg'))
    const xlMatch = useMediaQuery(theme.breakpoints.only('xl'))

    console.log({ xsMatch, smMatch, mdMatch, lgMatch, xlMatch })
}

export default useBreakPointDebugger

export const useThemeBreakPoints = () => {
    const theme = useTheme()

    const xsMatch = useMediaQuery(theme.breakpoints.only('xs'))
    const smMatch = useMediaQuery(theme.breakpoints.only('sm'))
    const mdMatch = useMediaQuery(theme.breakpoints.only('md'))
    const lgMatch = useMediaQuery(theme.breakpoints.only('lg'))
    const xlMatch = useMediaQuery(theme.breakpoints.only('xl'))

    const isLessThanLaptopWidth = useMediaQuery('(max-width:1020px)');

    return {
        xsMatch,
        smMatch,
        mdMatch,
        lgMatch,
        xlMatch,
        isLessThanLaptopWidth
    }
}
