import { Divider, Grid, useMediaQuery, useTheme } from '@mui/material'
import OverviewCard, { VideoCard } from './Components/OverviewCard'
import DividerCards from './Components/DividerCards'
import useBreakPointDebugger, {
    useThemeBreakPoints,
} from '../../Functions/useBreakPointDebugger'

const OverViewPage = () => {
    const theme = useTheme()
    const smDown = useMediaQuery(theme.breakpoints.down('md'))
    const onlySm = useMediaQuery(theme.breakpoints.only('sm'))
    const { xlMatch, isLessThanLaptopWidth } = useThemeBreakPoints()

    return (
        <Grid
            container
            sx={{
                height: onlySm ? undefined : '100%',
            }}
            justifyContent={smDown ? undefined : 'center'}
            alignItems={smDown ? undefined : 'center'}
        >
            <Grid
                item
                xs={12}
                sx={{
                    height: isLessThanLaptopWidth ? undefined : '75%',
                    paddingTop: onlySm ? 2 : 0,
                    paddingBottom: 1,
                }}
            >
                <OverviewInformationCard
                    smDown={smDown}
                    isLessThanLaptopWidth={isLessThanLaptopWidth}
                    xlMatch={xlMatch}
                />
            </Grid>

            <Grid
                item
                xs={12}
                sx={{ height: isLessThanLaptopWidth ? undefined : '25%' }}
            >
                <VolunteerCards smDown={smDown} onlySm={onlySm} />
            </Grid>
        </Grid>
    )
}

export default OverViewPage

type OverviewProp = {
    smDown: boolean
    isLessThanLaptopWidth?: boolean
    xlMatch?: boolean
    onlySm?: boolean
}
const OverviewInformationCard = ({
    smDown,
    isLessThanLaptopWidth,
    xlMatch,
}: OverviewProp) => {
    return (
        <Grid
            container
            alignItems={'center'}
            justifyContent={'center'}
            sx={{
                height: smDown ? undefined : '100%',
                pl: (theme) => theme.spacing(),
                pr: (theme) => theme.spacing(),
            }}
            direction={isLessThanLaptopWidth ? 'column-reverse' : 'row'}
        >
            <Grid
                item
                xs={3}
                md={6}
                lg={6}
                sx={{
                    paddingRight: (theme) => (smDown ? 0 : theme.spacing(2)),
                    paddingLeft: (theme) => theme.spacing(xlMatch ? 4 : 0),
                }}
            >
                <OverviewCard smDown={smDown} />
            </Grid>

            <Grid
                id="Youtube Player"
                item
                xs={4}
                md={6}
                lg={6}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    overflowX: 'auto',
                    overflowY: 'auto',
                }}
            >
                <VideoCard />
            </Grid>
        </Grid>
    )
}

const VolunteerCards = ({ smDown, onlySm }: OverviewProp) => {
    return (
        <Grid
            container
            sx={{ height: '100%', backgroundColor: '#DDE4E6' }}
            justifyContent={'center'}
            alignItems={'center'}
        >
            <DividerCards
                buttonLabel="Learn more"
                label="About the Orphans"
                route="about/children"
                smDown={smDown}
            />

            {smDown ? null : (
                <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    sx={{ backgroundColor: 'grey' }}
                />
            )}

            <DividerCards
                buttonLabel="Contact"
                label="Want to know more? Contact Us!"
                route="contact"
                smDown={smDown}
                borderTop
            />
        </Grid>
    )
}
