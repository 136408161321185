import { Menu, MenuItem } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { NavigationItem } from './ComponentType'

type MenuProps = {
    anchorEl: HTMLElement | null
    open: boolean
    handleClose: () => void
    data: NavigationItem[]
}

const NavigationMenu = ({ anchorEl, handleClose, open, data }: MenuProps) => {
    const navigate = useNavigate()

    const handleRoute = (route: string) => {
        handleClose()
        navigate(`${route}`)
    }

    return (
        <>
            <Menu
                id="route-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {data?.map((item) => (
                    <MenuItem
                        key={`${item?.label} - key`}
                        onClick={() => handleRoute(item?.route)}
                        sx={{ fontFamily: 'Bodoni', fontSize: 20 }}
                    >
                        {item?.label}
                    </MenuItem>
                ))}
            </Menu>
        </>
    )
}

export default NavigationMenu
