import { Grid, useMediaQuery, useTheme } from '@mui/material'
import OpeningPage from './OpeningPage'
import InformationPage from './InformationPage'

const AboutChildren = () => {
    const theme = useTheme()
    const smDown = useMediaQuery(theme.breakpoints.down('md'))
    return (
        <>
            <OpeningPage smDown={smDown} />
            {smDown ? null : <MiddleSection />}
            <InformationPage />
        </>
    )
}

export default AboutChildren

const MiddleSection = () => {
    return (
        <Grid
            container
            sx={{ height: '20%', backgroundColor: '#DDE4E6' }}
            justifyContent={'center'}
            alignItems={'center'}
            direction={'column'}
        ></Grid>
    )
}
