import { Grid } from '@mui/material'

type ImageProp = {
    source: string
    label: string
    percent?: string
}

const ResponsiveImage = ({ label, percent, source }: ImageProp) => {
    return (
        <img
            src={source}
            alt={label}
            style={{
                width: percent || '100%',
                height: 'auto',
            }}
        />
    )
}

export default ResponsiveImage

export const CoverImage = ({ label, source }: ImageProp) => {
    const containerStyle = {
        width: '100%',
        height: '100%',
        backgroundImage: `url(${source})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    }

    return <div style={containerStyle} aria-label={label} />
}

export const ContainedImage = ({ label, percent, source }: ImageProp) => {
    return (
        <img
            src={source}
            alt={label}
            style={{
                width: percent || '100%',
                height: 'contain',
            }}
        />
    )
}

export const ResizableHeightImage = ({ label, source }: ImageProp) => {
    return (
        <Grid
            item
            xs={12}
            sx={{
                height: '100%',
                backgroundImage: `url(${source})`,
                backgroundSize: 'fit',
                backgroundRepeat: 'no-repeat',
            }}
            container
        ></Grid>
    )
}
