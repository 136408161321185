import { Card, CardContent, CardHeader } from '@mui/material'
import { ThemedRouteButton } from '../../../Components/Button'
import YoutubePlayer from '../../../Components/YoutubePlayer'
import { useThemeBreakPoints } from '../../../Functions/useBreakPointDebugger'

type Props = {
    smDown: boolean
}
const OverviewCard = ({ smDown }: Props) => {
    const { mdMatch, isLessThanLaptopWidth } = useThemeBreakPoints()

    return (
        <Card elevation={0} sx={{ height: '100%' }}>
            <CardHeader
                title="Overview"
                titleTypographyProps={{
                    sx: {
                        overflowWrap: 'break-word',
                        fontFamily: 'Bodoni',
                        fontSize: isLessThanLaptopWidth ? 30 : 45,
                        fontWeight: 'bold',
                    },
                }}
            />
            <CardContent
                sx={{
                    overflowWrap: 'break-word',
                    fontFamily: 'Bodoni',
                    fontSize: isLessThanLaptopWidth ? 20 : mdMatch ? 28 : 30,
                }}
            >
                Orphanages of Vietnam is about building a community that is
                passionate about supporting and improving the lives of less
                fortunate children.
            </CardContent>
            <ThemedRouteButton title="About Us" routePath="about/us" />
        </Card>
    )
}

export default OverviewCard

export const VideoCard = () => {
    return <YoutubePlayer />
}
