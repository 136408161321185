import { ArrowRight } from '@mui/icons-material'
import { Grid, List, ListItem, ListItemIcon, Typography } from '@mui/material'
import flowerfield from '../../../Resources/flower-field.jpg'

type Prop = {
    smDown?: boolean
}
const ValuesPage = ({ smDown }: Prop) => {
    return (
        <Grid
            container
            /* sx={{ height: smDown ? undefined : '100%' }} */
            justifyContent={smDown ? 'center' : undefined}
        >
            <Grid
                item
                xs={10}
                md={6}
                sx={{
                    paddingLeft: smDown ? 0 : 10,
                    paddingTop: smDown ? 4 : 10,
                    paddingBottom: 10,
                }}
            >
                <Information smDown />
            </Grid>
            {smDown ? null : (
                <Grid
                    item
                    xs={4}
                    sx={{
                        paddingLeft: 10,
                        paddingTop: 10,
                        paddingBottom: 10,
                        marginLeft: 20,
                        pr: 4,
                    }}
                >
                    <ImagesCard />
                </Grid>
            )}
        </Grid>
    )
}

export default ValuesPage

type InfoProp = {
    smDown?: boolean
}
const Information = ({ smDown }: InfoProp) => {
    return (
        <Grid
            container
            direction={'column'}
            sx={{ height: '100%' }}
            alignItems={smDown ? 'center' : undefined}
        >
            {smDown ? (
                <>
                    <Grid item sx={{ borderBottom: 1, borderColor: 'grey' }}>
                        <Typography variant="h4" sx={{ fontFamily: 'Bodoni' }}>
                            Our History
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: 20,
                                paddingTop: 4,
                                paddingBottom: 4,
                                fontFamily: 'times-new-roman',
                            }}
                        >
                            Orphanages of Vietnam is a New Jersey-based
                            nonprofit charitable organization founded by Rachael
                            Thai in 2023. Our team works to promote awareness
                            about the conditions of the orphanages and the needs
                            of the children. We want to contribute to foundation
                            on which children can experience happy childhoods
                            and achieve their full potentials.
                        </Typography>
                    </Grid>
                </>
            ) : (
                <Grid
                    item
                    sx={{
                        fontSize: 20,
                        paddingTop: 5,
                        fontFamily: 'times-new-roman',
                    }}
                >
                    Orphanages of Vietnam is a New Jersey-based nonprofit
                    charitable organization founded by Rachael Thai in 2023. Our
                    team works to promote awareness about the conditions of the
                    orphanages and the needs of the children. We want to
                    contribute to foundation on which children can experience
                    happy childhoods and achieve their full potentials.
                </Grid>
            )}

            <Grid item sx={{ paddingTop: smDown ? 4 : 10 }}>
                <Typography
                    variant={smDown ? 'h4' : 'h6'}
                    sx={{ fontFamily: 'Bodoni' }}
                >
                    Our core values:
                </Typography>

                <List>
                    <ListItem
                        sx={{
                            fontSize: smDown ? 18 : 22,
                            paddingTop: 2,
                            fontFamily: 'times-new-roman',
                        }}
                    >
                        {smDown ? null : (
                            <ListItemIcon>
                                <ArrowRight />
                            </ListItemIcon>
                        )}
                        We believe that children deserve to have the opportunity
                        to create and shape their own future
                    </ListItem>
                    <ListItem
                        sx={{
                            fontSize: smDown ? 18 : 22,
                            paddingTop: 2,
                            fontFamily: 'times-new-roman',
                        }}
                    >
                        {smDown ? null : (
                            <ListItemIcon>
                                <ArrowRight />
                            </ListItemIcon>
                        )}
                        All the basic needs of a child (social, emotional and
                        mental) must be met in order for a child to enjoy their
                        childhood
                    </ListItem>
                    <ListItem
                        sx={{
                            fontSize: smDown ? 18 : 22,
                            paddingTop: 2,
                            fontFamily: 'times-new-roman',
                        }}
                    >
                        {smDown ? null : (
                            <ListItemIcon>
                                <ArrowRight />
                            </ListItemIcon>
                        )}
                        OOVN believes childrens’ futures should be our top
                        priority
                    </ListItem>
                </List>
            </Grid>
        </Grid>
    )
}

const ImagesCard = () => {
    const percent = '100%'
    return (
        <Grid
            item
            container
            justifyContent={'space-evenly'}
            sx={{ height: '100%' }}
        >
            <Grid item sx={{ paddingBottom: 8 }}>
                <img
                    src={flowerfield}
                    alt="flower-field"
                    style={{
                        maxWidth: percent,
                        height: 'auto',
                    }}
                />
            </Grid>
            <Grid item>
                <img
                    src={flowerfield}
                    alt="flower-field"
                    style={{
                        maxWidth: percent,
                        height: 'auto',
                    }}
                />
            </Grid>
        </Grid>
    )
}

;<>
    <Grid item sx={{ borderBottom: 1, borderColor: 'grey' }}>
        <Typography variant="h4" sx={{ fontFamily: 'Bodoni' }}>
            Our History
        </Typography>
        <Typography
            sx={{
                fontSize: 16,
                paddingTop: 4,
                paddingBottom: 4,
            }}
        >
            Orphanages of Vietnam is a New Jersey-based nonprofit charitable
            organization founded by Rachael Thai in 2023. Our team works to
            promote awareness about the conditions of the orphanages and the
            needs of the children. We want to contribute to foundation on which
            children can experience happy childhoods and achieve their full
            potentials.
        </Typography>
    </Grid>
</>
